import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionHeader from '../../components/SectionHeader'
import SectionTextTwoColumns from '../../components/SectionTextTwoColumns'
import Layout from '../../layout/Layout'

export default function Home() {
  const { headerBg } = useStaticQuery(
    graphql`
      query {
        headerBg: file(
          relativePath: { eq: "nasze-uslugi/transport-ladunkow-sypkich.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Layout
      metaTitle="Transport ładunków sypkich"
      metaDescription="Świadczymy kompleksowe usługi spedycyjne, które obejmują m.in. transport różnego rodzaju materiałów sypkich na terenie, zarówno Polski, jak i pozostałych krajów Europy, w tym również państw nienależących do struktur UE, jak Rosja, Białoruś, Ukraina, Gruzja, Armenia, czy Mołdawia."
      slug="/uslugi/transport-ladunkow-sypkich"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            ładunków <br />
            sypkich
          </>
        }
        titleBorder="white"
        lead="Świadczymy kompleksowe usługi spedycyjne, które obejmują m.in. transport różnego rodzaju materiałów sypkich na terenie, zarówno Polski, jak i pozostałych krajów Europy, w tym również państw nienależących do struktur UE, jak Rosja, Białoruś, Ukraina, Gruzja, Armenia, czy Mołdawia. "
        content="Z powodzeniem docieramy z ładunkami do dowolnego zakątka Starego Kontynentu, a&nbsp;nawet dalej, dzięki czemu możemy skutecznie wspierać Twoją działalność na polu eksportu i&nbsp;importu. W&nbsp;razie potrzeby pomagamy w&nbsp;czynnościach celnych, którymi zajmujemy się w&nbsp;Twoim imieniu, a&nbsp;Ty oszczędzasz czas i&nbsp;możesz spożytkować go na inne czynności."
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        halfBg
        bgImage={getImage(headerBg)}
      />
      <SectionTextTwoColumns
        id="czytaj-wiecej"
        smallTitle="Transport ładunków sypkich"
        title={
          <>
            Transport
            <br />
            materiałów sypkich
            <br />w całej Europie
          </>
        }
        lead={
          <>
            Oferowany przez naszą firmę transport towarów sypkich wymaga
            wykorzystania specjalnych, odpowiednio dobranych metod, środków
            i&nbsp;pojazdów, które gwarantują bezpieczną i&nbsp;efektywną
            realizację zlecenia.
          </>
        }
      >
        <p>
          Zdajemy sobie z&nbsp;tego sprawę, dlatego stosujemy specjalistyczne
          urządzenia i&nbsp;narzędzia przeznaczone do załadunku, rozładunku
          i&nbsp;przewozu rozmaitych materiałów sypkich. To możliwe poprzez
          dobór sprawdzonych oraz rzetelnych Podwykonawców, z&nbsp;którymi
          współpracujemy na terenie całej Polski i&nbsp;Europy. Rozbudowana sieć
          partnerów jest jednym z&nbsp;naszych najważniejszych
          i&nbsp;najcenniejszych zasobów, pozwalających na profesjonalne
          wykonywanie wszelkich zleceń.
        </p>
        <p>
          <b>
            Zapewniamy transport dowolnych towarów sypkich. Zapraszamy do
            współpracy, jeśli chcesz dostarczyć m.in.:
          </b>
          <ul>
            <li>
              surowce i materiały budowlane (np. piasek, gips, żwir, cement)
            </li>
            <li>
              płody rolne i&nbsp;produkty spożywcze (np. ziarna, sól, cukier,
              mąka, nasiona, kawa, herbata, kakao)
            </li>
            <li>
              środki chemiczne (np. proszki, barwniki, nawozy, wypełniacze)
            </li>
            <li>odpady (np. pył, sadza, ziemia).</li>
          </ul>
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ładunków sypkich"
        title={
          <>
            Kompleksowy <br />
            i bezpieczny <br />
            transport ładunków sypkich
          </>
        }
        lead={
          <>
            Każde zlecenie realizujemy w&nbsp;sposób indywidualny i&nbsp;zarazem
            elastyczny – metody, środki i&nbsp;narzędzia zawsze dobieramy pod
            kątem danych materiałów, których przewóz wykonujemy.
          </>
        }
      >
        <p>
          Działamy precyzyjnie, metodycznie i&nbsp;efektywnie, dzięki czemu
          w&nbsp;krótkim czasie dostarczamy towary pod wskazany adres. Poza
          skutecznością gwarantujemy optymalizację współpracy pod względem
          finansowym, poprzez dobór rozwiązań ograniczających koszty do minimum.
          Jeżeli zależy Ci na połączeniu wysokiej jakości i&nbsp;kompleksowości
          świadczonych usług z&nbsp;korzystnymi stawkami, możesz nam zaufać!
        </p>
        <p>
          Tym, co wyróżnia naszą ofertę, jest kompleksowa obsługa
          i&nbsp;kontrola procesów transportowych. Dbamy ovTwoje towary na
          każdym etapie współpracy – standardowo organizujemy przewóz ładunków,
          jednak na życzenie Klienta organizujemy również załadunek oraz
          rozładunek towaru. Doświadczony zespół spedytorów realizujących Twoje
          zlecenie gwarantuje terminowość i&nbsp;bezpieczeństwo dostaw,
          niezależnie od miejsca docelowego. Nawet jeśli chcesz przewieźć
          materiały sypkie poza granice Europy, możesz powierzyć nam to zadanie.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ładunków sypkich"
        title={
          <>
            Pełna kontrola <br />
            nad towarami
          </>
        }
        lead={
          <>
            Nawet na najdalszych trasach zapewniamy całodobowy, zdalny
            monitoring Twoich ładunków. Oznacza to, że w&nbsp;każdej chwili
            możesz sprawdzić, gdzie się znajdują, ile czasu zajmie jeszcze
            podróż i&nbsp;jaką trasą się ona odbywa.
          </>
        }
      >
        <p>
          Wszystko to z&nbsp;myślą o&nbsp;pełnym bezpieczeństwie współpracy
          i&nbsp;precyzyjnej kontroli nad dostarczanymi towarami. Gwarancja
          szybkiego kontaktu z&nbsp;dedykowanym opiekunem i&nbsp;pełna
          dyspozycyjność to kolejne aspekty, które składają się na profesjonalną
          obsługę zleceń. Jeśli masz jakiekolwiek pytania, wystarczy telefon do
          nas, żeby uzyskać odpowiedzi.
        </p>
        <p>
          Mamy świadomość, że zależy Ci na sprawnej realizacji zlecenia, dlatego
          proponujemy jasne warunki i&nbsp;minimum formalności. Stawka jest
          z&nbsp;góry określona, a&nbsp;umowa współpracy skonstruowana
          w&nbsp;sposób zrozumiały i&nbsp;uwzględniający interesy obu stron.
          Wykonujemy przewóz ładunków sypkich w&nbsp;ramach, zarówno
          pojedynczych, jak i&nbsp;stałych, regularnych dostaw. Dysponujemy
          zasobami i&nbsp;kadrą, które pozwalają na wykonywanie transportu nawet
          bardzo dużych ilości materiałów, które wymagają zaangażowania kilku
          pojazdów.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ładunków sypkich"
        title={
          <>
            Korzystny <br />
            outsourcing <br />
            transportu <br />
            ładunków sypkich
          </>
        }
        lead={
          <>
            Na każdym etapie realizacji zlecenia dbamy o&nbsp;przestrzeganie
            aktualnych przepisów transportowych i&nbsp;BHP. Pilnujemy
            obowiązujących norm i&nbsp;stosujemy środki, metody oraz narzędzia
            do przewozu dopuszczone przez obecne prawo.
          </>
        }
      >
        <p>
          Równomiernie rozmieszczamy sypkie towary na całej powierzchni pojazdu,
          tak aby zagwarantować ich bezpieczeństwo. Troszczymy się także
          o&nbsp;ochronę ładunków przed zniszczeniem, rozsypaniem, czy kontaktem
          z&nbsp;zewnętrznymi czynnikami, jak np. opady, które w&nbsp;danym
          przypadku mogą być szkodliwe dla przewożonych produktów.
        </p>
        <p>
          Jeżeli interesuje Cię transport materiałów sypkich i&nbsp;poszukujesz
          doświadczonej firmy, której możesz zlecić outsourcing tego rodzaju
          usług przewozowych, skontaktuj się z&nbsp;nami. Zapraszamy do
          współpracy, zarówno małe, średnie i&nbsp;duże przedsiębiorstwa
          z&nbsp;dowolnych branż, jak i&nbsp;osoby prywatne, które poszukują
          sprawdzonego i&nbsp;godnego zaufania partnera, oferującego kompleksowy
          transport towarów sypkich.
        </p>
      </SectionTextTwoColumns>
    </Layout>
  )
}

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as Styled from './SectionHeader.styles'
import { Column, Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'
import IconMarker from '../../images/icons/marker.svg'

const SectionHeader = ({
  title,
  smallTitle,
  titleBorder,
  titleVariant,
  titleComponent = 'h1',
  lead,
  content,
  scroll,
  buttonType = 'primary',
  buttonText,
  halfBg,
  bgImage,
  contentCentered,
  nordicLogistics,
}) => {
  return (
    <Styled.Section
      Tag="section"
      image={bgImage}
      halfBg={halfBg}
      nordicLogistics={nordicLogistics}
      contentCentered={contentCentered}
    >
      <Row>
        <Column xs={12} lg={6}>
          <Styled.ContentWrapper>
            <DoubleTitle
              smallTitle={smallTitle}
              title={title}
              component={titleComponent}
              variant={titleVariant || null}
              border={titleBorder}
            />
            <p className="lead">{lead}</p>
            {content && <p>{content}</p>}
            {scroll && (
              <Button
                primary={buttonType === 'primary'}
                bordered={buttonType === 'bordered'}
                scroll={scroll}
              >
                {buttonText || 'Czytaj więcej'}
              </Button>
            )}
          </Styled.ContentWrapper>
        </Column>
        {nordicLogistics && (
          <Column xs={12} lg={6}>
            <IconMarker />
            <StaticImage
              src="../../images/home/nordic-logistics.png"
              alt="Nordic Logistics - transport drogowy"
            />
          </Column>
        )}
      </Row>
    </Styled.Section>
  )
}

export default SectionHeader

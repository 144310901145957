import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 32px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 120px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 160px 0;
  }

  & + & {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      padding-top: 40px !important;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      padding-top: 60px !important;
    }
  }

  .lead {
    margin-bottom: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-bottom: 32px;
    }
  }

  .h3 + .lead {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      max-width: 80%;
    }
  }

  .icons-list {
    text-align: center;
    margin-top: 64px;

    @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
      display: flex;
      justify-content: space-around;
    }

    .lead {
      text-transform: uppercase;
      margin-top: 16px;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        margin-bottom: 32px;
      }

      @media (min-width: ${props => props.theme.breakpoints.mdUp}) {
        margin-top: 32px;
      }
    }

    svg {
      @media (max-width: ${props => props.theme.breakpoints.md}) {
        height: 80px;
      }
    }
  }
`

export { Section }

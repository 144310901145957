import styled, { css } from 'styled-components'
import { BgImage } from 'gbimage-bridge'

const Section = styled(BgImage)`
  @keyframes moveOnInit {
    0% {
      transform: scale(0.8) translate(0, 0);
    }
    100% {
      transform: scale(1) translate(-20%, 5%);
    }
  }

  background-color: ${props => props.theme.colors.secondary};
  color: #fff;
  position: relative;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding-top: 100%;
    background-size: contain !important;
    background-position: top !important;

    &:before,
    &:after {
      background-size: contain !important;
      background-position: top !important;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    display: flex;
    align-items: flex-end;
    min-height: 100vh;
  }

  p {
    color: #fff !important;

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      max-width: 85%;
    }
  }

  ${props =>
    props.halfBg === true &&
    css`
      background-color: ${props.theme.colors.primary};

      @media (max-width: ${props.theme.breakpoints.lg}) {
        &:before,
        &:after {
          height: 100vw !important;
          background-size: cover !important;
          background-position: center !important;
        }
      }
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        &:before,
        &:after {
          left: 50% !important;
          width: 50% !important;
        }
      }
    `}

  ${props =>
    props.nordicLogistics === true &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        &:before,
        &:after {
          background-position: bottom !important;
        }
      }

      svg {
        position: absolute;
        z-index: 1;
        left: 50%;

        @media (max-width: ${props.theme.breakpoints.lg}) {
          top: 20vw;
          height: 90vw;
          width: auto;
          transform: translateX(-50%);
        }

        @media (min-width: ${props.theme.breakpoints.lgUp}) {
          width: calc(50vw - 20px);
          height: auto;
          bottom: -80px;
        }

        @media (min-width: 1600px) {
          width: 800px;
          bottom: -100px;
        }
      }

      .gatsby-image-wrapper {
        z-index: 2;
        position: absolute;

        @media (max-width: ${props.theme.breakpoints.lg}) {
          left: 50%;
          top: 30vw;
          transform: translateX(-50%);
          width: 70vw;
        }

        @media (min-width: ${props.theme.breakpoints.lgUp}) {
          transform: scale(0.8);
          bottom: 15%;
          animation-name: moveOnInit;
          animation-duration: 1s;
          animation-delay: 1.5s;
          animation-fill-mode: forwards;
        }
      }
    `}

  ${props =>
    props.contentCentered &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        align-items: center;
      }
    `}
`

const ContentWrapper = styled.div`
  padding: 60px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding-top: 120px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding-top: 140px;
  }

  a {
    margin-top: 20px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-top: 40px;
    }
  }
`

export { Section, ContentWrapper }

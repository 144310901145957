import React from 'react'
import * as Styled from './SectionTextTwoColumns.styles'
import { Column, Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'

const SectionTextTwoColumns = ({
  id,
  title,
  smallTitle,
  lead,
  reverseLarge,
  children,
}) => {
  return (
    <Styled.Section reverseLarge={reverseLarge} id={id}>
      <Row alignMiddle alignRight>
        <Column xs={12} lg={5} largeOrder={reverseLarge ? 2 : 1}>
          <DoubleTitle
            smallTitle={smallTitle}
            title={title}
            variant="h3"
            border="addon"
          />
          <p className="lead">{lead}</p>
        </Column>
        <Column xs={12} lg={7} xl={6} largeOrder={reverseLarge ? 1 : 2}>
          {children}
        </Column>
      </Row>
    </Styled.Section>
  )
}

export default SectionTextTwoColumns
